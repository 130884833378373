import React, {useEffect, useState, useRef} from 'react';
import moment from "moment";
import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    Radio,
    Space,
    InputNumber,
    Alert,
    Collapse, Tag, Tooltip, Card
} from "antd";
import api from "../../middleware/api";
import { DayPicker } from 'react-day-picker';
import de from 'date-fns/locale/de';
import BookingSlotSelector from "../../components/BookingSlotSelector";
import {PhoneOutlined, InfoCircleOutlined, DownOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useApp} from "../app/AppProvider";
import PriceTable from "./PriceTable";
import ProductSelector from "../../components/ProductSelector";

const { Panel } = Collapse;

const slotErrors = {
    NONE: 'NONE',
    SLOTS_FULL: 'SLOTS_FULL',
};


const productErrors = {
    NONE: 'NONE',
    NONE_AVAILABLE: 'NONE_AVAILABLE',
};


Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

const BookingMessageBlock = ({icon, text}) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <span style={{fontSize: '5rem', color: 'var(--global-main-color)'}}>{icon && icon}</span>
            <p style={{fontSize: '1.4rem'}}>{text}</p>
        </div>
    );
};

const Book = ({onSuccess, initialValues, blockedBookings, showBack, onBack}) => {

    const { mandatorInfo} = useApp();

    const [saving, setSaving] = useState(false);
    const [products, setProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [hiddenProducts, setHiddenProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [multiSlotsBooking, setMultiSlotsBooking] = useState(false);
    const [slots, setSlots] = useState([]);
    const [priceOptions, setPriceOptions] = useState([]);
    const [resources, setResources] = useState([]);
    const [error, setError] = useState(false);
    const [minBookingDate, setMinBookingDate] = useState(new Date());
    const [customBookingDates, setCustomBookingDates] = useState([]);


    const [discountPrice, setDiscountPrice] = useState(initialValues?.discountPrice ||[]);
    const [price, setPrice] = useState(initialValues?.price || {singlePrice: 0, summary: 0});

    const [slotError, setSlotError] = useState(slotErrors.NONE);
    const [productError, setProductError] = useState(productErrors.NONE);

    const productsRef = useRef([]);

    const [form] = Form.useForm();

    useEffect(() => {
        loadProducts();
        loadCategories();
    }, []);

    useEffect(() => {
        filterProducts(false);
    }, [products]);

    const loadProducts = async () => {

        const response = await api.get(`product/public`);

        if(response.data) {
            const newProducts = response.data;
            await setProducts(newProducts);
            productsRef.current = newProducts;
            if (!initialValues.category && initialValues.productId) {
                const actProduct = newProducts.find(product => product.id === initialValues.productId)?.category || "";
                form.setFieldsValue({category: actProduct});
                setProductInfos(initialValues.productId);
            }
        }
    };

    const loadCategories = async () => {

        const response = await api.get(`product/public/categories`);

        if(response.data) {
            await setCategories(response.data);
        }
    };

    const filterProducts = (reset = true) => {

        setProductError(productErrors.NONE);
        setAvailableProducts([]);
        reset && setHiddenProducts([]);

        let minAge = 0;
        let maxAge = 0;

        const formValues = form.getFieldsValue(["minAge", "maxAge", "persons", "category", "productId"]);

        if (window.ENV.ASK_AGE) {
            minAge = parseInt(formValues.minAge);
            maxAge = parseInt(formValues.maxAge);
        }



        let enabledProducts = [];
        let _hiddenProducts = [];


        products.forEach(product => {

            const minPersons = product.minPersonsPerBooking || product.minPersons;
            const maxPersons = product.maxPersonsPerBooking || product.maxPersons;

            if(
            (!window.ENV.ASK_AGE || (product.minAge <= minAge &&
            (!product.maxAge || product.maxAge >= maxAge))) &&
            minPersons <= formValues.persons &&
            (!maxPersons || maxPersons >= formValues.persons)
            ) {
                enabledProducts.push(product.id);
            }

            if(formValues.category && (product.category || "") !== (formValues.category || "")) {
                _hiddenProducts.push(product.id);
            }
        });

        if(!formValues.productId || (formValues.productId && (!enabledProducts.includes(formValues.productId) || _hiddenProducts.includes(formValues.productId)))) {
            if(reset) {
                form.setFieldsValue({productId: undefined});
                setResources([]);
                setPriceOptions([]);
                setSlots([]);
                form.setFieldsValue({bookingSlots: []});
                setPrices([]);
            }

        }

        setAvailableProducts(enabledProducts);
        setHiddenProducts(_hiddenProducts);


        // check if required fields are filled
        if(!formValues.persons || (window.ENV.ASK_AGE && (!formValues.minAge || !formValues.maxAge)) ) {
            return;
        }
        // check if max age is smaller than min age
        if (window.ENV.ASK_AGE) {
            if(minAge > maxAge) {
                return;
            }
        }


        //loadSlots(false).then(() => checkSelectedSlots());
        loadSlots(false);

        if(!enabledProducts.length) {
            setProductError(productErrors.NONE_AVAILABLE);
        }


        if (enabledProducts.length === 1) {
            reset && form.setFieldsValue({productId: enabledProducts[0]});
            setProductInfos(enabledProducts[0]);
        }

    };


    /*const loadProducts = async (reset = true) => {

        setProductError(productErrors.NONE);

        const formValues = form.getFieldsValue(["minAge", "maxAge", "persons"]);

        if(reset) {
            form.setFieldsValue({productId: undefined});
            setResources([]);
            setSlots([]);
        }
        setProducts([]);

        if(!formValues.minAge || !formValues.maxAge || !formValues.persons) {
            return;
        }

        const searchParams = new URLSearchParams({
            minAge: formValues.minAge,
            maxAge: formValues.maxAge,
            persons: formValues.persons,
        });

        const response = await api.get(`product/public/list?${searchParams.toString()}`);

        if(response.data) {
            await setProducts(response.data);
            if (response.data.length === 0) {
                setProductError(productErrors.NONE_AVAILABLE);
                setSlots([]);
            } else if (response.data.length === 1) {
                reset && form.setFieldsValue({productId: response.data[0].id});
                setProductInfos(response.data[0].id, response.data);
            }
        }
    };*/

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const setProductInfos = (productId = null, productList) => {
        const _productId = productId || form.getFieldsValue(['productId']).productId;
        const _products = productList || products;
        const _product = _products.find(product => product.id === _productId) || {};

        const isMultiSlotsBooking = _product?.multiSlotsBooking || false;
        setMultiSlotsBooking(isMultiSlotsBooking);

        const blockingTime = _product?.blockingTime || 0;

        // customDates must include disbaled dates in format
        // { from: Date, to: Date }
        // availableDates comes in format [{ startDate: Date, endDate: Date }]
        const customDates = [];
        (_product.availableDates || [])?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)).map((date, index) => {
            // we need to convert availableDates into disabled dates
            // so we need to add one day to the end date
            if(index === 0) {
                // till first available date minus one day
                customDates.push({ from: new Date(0), to: new Date(date.startDate).addHours(-21) });
            }

            const nextDate = _product.availableDates[index + 1];

            customDates.push({ from: new Date(date.endDate).addHours(21), to: new Date(nextDate?.startDate || 8640000000000000).addHours(-21) });
        });

        setCustomBookingDates(customDates || []);

        // if only one date is available, set it as default
        if((_product.availableDates || []).length === 1) {
            form.setFieldsValue({date: moment(_product.availableDates[0].startDate).toDate()});
        }

        setMinBookingDate(new Date().addHours(blockingTime));

        loadResources();
    };

    const loadSlots = async (reset= true) => {
        setSlotError(slotErrors.NONE);

        const formValues = form.getFieldsValue(["productId", "resourceId", "persons", "date"]);

        reset && form.setFieldsValue({bookingSlots: undefined});

        if(!formValues.productId || !formValues.persons || !formValues.resourceId || !(formValues.date && isValidDate(formValues.date))) {
            if(slots.length > 0) {
                setSlots([]);
            }
            return;
        }

        const actDate = moment(formValues.date).local().format("YYYY-MM-DD");

        /*const blockingTime = products.find(product => product.id === formValues.productId)?.blockingTime || 0;


        const actDate = new Date().toLocaleString('de-DE', {
            timeZone: 'Europe/Berlin',
            dateStyle: 'short',
            timeStyle: 'medium',
        });

        const minBookingDate = moment(actDate, 'DD.MM.YYYY, HH:mm:ss').add(
            moment.duration(blockingTime, 'hour'),
        );

        const selectedDate = moment(form.getFieldsValue(['date'])?.date);

        console.log("selectedDate", selectedDate.toISOString());

        if(selectedDate.isBefore(minBookingDate)) {
            setSlotError(slotErrors.TOO_EARLY);
            setSlots([]);
            return;
        }*/

        const searchParams = new URLSearchParams({
            productId: formValues.productId,
            persons: formValues.persons,
            date: actDate,
            resourceId: formValues.resourceId,
        });

        if(initialValues?.extraSession) {
            searchParams.append('extraSession', initialValues?.extraSession);
        }

        const response = await api.get(`booking/public/slots?${searchParams.toString()}`);
        let newSlots = [];

        if(response.data) {
            newSlots = response.data?.data || [];

            const productInfo = products.find(product => product.id === formValues.productId) || {};

            const actBlockedBookings = (blockedBookings || []).filter(booking => booking.productId === formValues.productId && moment(booking.date).local().format("YYYY-MM-DD") === actDate);

            /*actBlockedBookings.forEach(blockedBooking => {
                newSlots = newSlots.filter(slot => !blockedBooking.bookingSlots.includes(slot.startDate));
            });*/

            actBlockedBookings.forEach(blockedBooking => {

                const blockedBookingsDates = blockedBooking.bookingSlots.map((blockedStart) => ({startDate: moment(blockedStart, 'YYYY-MM-DD HH:mm'), endDate: moment(
                        blockedStart,
                        'YYYY-MM-DD HH:mm',
                    ).add(moment.duration(productInfo.duration))}));

                newSlots = newSlots.filter(slot => !blockedBookingsDates.find(blockedTime => moment(slot.startDate, 'YYYY-MM-DD HH:mm').isBetween(blockedTime.startDate, blockedTime.endDate, undefined, '[]') || moment(slot.endDate, 'YYYY-MM-DD HH:mm').isBetween(blockedTime.startDate, blockedTime.endDate, undefined, '[]')));
            });


            await setSlots(newSlots);
            checkSelectedSlots(newSlots);
            if(newSlots.length === 0) {
                setSlotError(slotErrors.SLOTS_FULL);
            }
        } else {
            await setSlots([]);
            checkSelectedSlots([]);
        }
    };

    const checkSelectedSlots = async (_slots) => {
        let selectedSlots = form.getFieldsValue(["bookingSlots"])?.bookingSlots || [];

        const availableSlots = _slots.map(slot => slot.startDate);
        if(!selectedSlots.every(selSlot => availableSlots.includes(selSlot))) {
            await form.setFieldsValue({bookingSlots: []});
            selectedSlots = [];
        }
        setPrices(selectedSlots, _slots);
    };


    const loadResources = async (reset = true) => {

        const formValues = form.getFieldsValue(["productId"]);
        const productId = formValues.productId;

        if(!productId) {
            setSlots([]);
            return;
        }

        const searchParams = new URLSearchParams({
            productId,
        });

        const response = await api.get(`resource/public/list?${searchParams.toString()}`);
        if(response.data) {

            setResources(response.data);

            if (response.data.length === 1) {
                reset && form.setFieldsValue({resourceId: response.data[0].id});
                await loadSlots(false);
            } else if(!response.data.length) {
                await setSlots([]);
            }
            loadPriceOptions();
        }
    };

    const loadPriceOptions = async () => {
        const { productId, resourceId } = form.getFieldsValue(["productId", "resourceId"]);
        if(productId && resourceId) {
            const response = await api.get(`pricelist/options/${resourceId}/${productId}`);

            if(response.data) {
                setPriceOptions(response.data || []);
            }
        } else {
            setPriceOptions([]);
        }
    };


    /*const checkResourceSelection = (value) => {
        const actResourceSelectable = products.find(product => product.id === value)?.resourceSelectable || false;
        setResourceSelectable(actResourceSelectable);
        form.setFieldsValue({resourceId: ""});
        if (actResourceSelectable) {
            loadResources();
        } else {
            setResources([]);
            loadSlots();
        }
    };*/


    const handleSubmit = async (values) => {
        try {
            setSaving(true);


            onSuccess({
                ...values,
                date: values.date ? moment(values.date).local().format("YYYY-MM-DD") : undefined,
                price,
                discountPrice,
                priceOptions: (Object.keys(values.priceOptionsValues || {}).map((option) => ({ name: option, persons: values.priceOptionsValues[option] })) || []).filter(option => option.persons),
            });

            //const queryParams = initialValues?.extraSession ? `?extraSession=${initialValues?.extraSession}` : '';

            //const response = await api.put(`booking/public/reserve${queryParams}`, values);

            //if (response.data) {
                //onSuccess({...values, price, discountPrice, extraSession: response.data});
            //}

        } catch(e) {
            window.scrollTo(0,0);
            switch(e.response.status) {
                case 410:
                    setError("Die Reservierungszeiten sind leider nicht mehr verfügbar. Bitte eine andere Zeit auswählen.");
                    break;
                default:
                    setError("Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen.");
                    break;
            }

            console.error(e);
        } finally {
            setSaving(false);
        }
    };

    const checkInitial = async () => {
        if(initialValues) {
            filterProducts(false);
            await loadResources(false);
            await loadSlots(false);
        }
    };

    useEffect(() => {
       checkInitial();
    }, [initialValues]);

    useEffect(() => {
        const newCategory = mandatorInfo?.defaultCategory;
        if (Object.keys(initialValues).length < 4 && !initialValues?.productId && categories.length > 1 && newCategory) {
            let _hiddenProducts = [];
            form.setFieldsValue({category: newCategory});
            products.map(product => { if(product.category !== newCategory) {
                _hiddenProducts.push(product.id);
            }
            setHiddenProducts(_hiddenProducts);
            });
        }
    }, [categories, products]);


    /*const setAges = (over18) => {
        if(over18 === "1") {
            form.setFieldsValue({minAge: "18", maxAge: "18"});
        } else {
            form.setFieldsValue({minAge: undefined, maxAge: undefined});
        }
        loadProducts(true);
    };*/

    const calculatePriceOption = (actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys) => {

        const result = {
            sumPersons: 0,
            sumPrice: 0,
            options: []
        }
        const actPriceOptions = activePriceOptionKeys.map(optionName => {
            const personsWithThisOption = Math.min((priceOptionValues?.[optionName] || 1), persons);

            // price options with current name
            let priceOptionPersonsToUse = (actPriceInfo.priceOption || []).filter(infoOption => infoOption.name === optionName) || [];

            // filter to price options with less (or equal) persons than currently selected
            priceOptionPersonsToUse = priceOptionPersonsToUse.map((priceOption) => priceOption.persons).filter((priceOptionPersons) => priceOptionPersons <= personsWithThisOption);
            // filter out max persons - we want to use the cheapest option so we need to find the one with max persons
            priceOptionPersonsToUse = Math.max(...priceOptionPersonsToUse, 1);

            // return price option to use
            return (actPriceInfo.priceOption || []).find(infoOption => infoOption.name === optionName && infoOption.persons === priceOptionPersonsToUse);
        }).filter(option => option);

        actPriceOptions.forEach(priceOption => {


            const personsWithThisOption = Math.min((priceOptionValues?.[priceOption.name] || 1), persons);
            const actSinglePrice = parseFloat(priceOption.pricePerPerson || 0);


            result.sumPersons += personsWithThisOption;

            result.options.push(
                {
                    position: `${priceOption.name} - ${moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr`,
                    persons: personsWithThisOption,
                    singlePrice: `${actSinglePrice.toFixed(2).replace('.', ',')} €`,
                    summary: `${parseFloat(actSinglePrice * personsWithThisOption).toFixed(2).replace('.', ',')} €`,
                }
            )
            result.sumPrice += parseFloat(actSinglePrice * personsWithThisOption);

        });

        return result;
    }


    const setPrices = (selectedStartDates, customSlots) => {

        const _slots = customSlots || slots;

        const productId = form.getFieldsValue(['productId']).productId || initialValues.productId;

        let priceOptionValues = form.getFieldsValue(['priceOptionsValues']).priceOptionsValues || {};
        const activePriceOptionKeys = Object.keys(priceOptionValues).filter(key => priceOptionValues[key] > 0);


        const productInfo = (products || productsRef.current).find(product => product.id === productId) || {};

        if(!Object.keys(productInfo).length) {
            return;
        }

        let discountsToSet = [];
        let priceSurcharge = 0;

        //let newPrices = { singlePrice: 0, summary: 0 };

        let newPrices = { priceList: [], singlePrice: 0, groupPrice: 0,  summary: 0 };

        let persons = form.getFieldsValue(["persons"])?.persons || 1;
        const allPersons = persons;

        const priceInfo = _slots.find(slot => selectedStartDates.includes(slot.startDate))?.priceList || {};
            //const priceList = priceInfo?.price || [];
        //console.log("priceList priceList ", priceList);

        // calculate price options

        if (priceInfo.type === 'discount') {


            const priceList = priceInfo?.priceSlot || [];
            if (priceList.length > 1) {
                discountsToSet = priceList.filter(price => price.gameNumber !== 1);

                /*discountsToSet = discountsToSet.map(discount => {
                    if (persons >= (priceInfo?.groupDiscountMinPersons || 0)) {
                        discount.pricePerPerson = (discount.pricePerPerson * ((100 - (priceInfo?.groupDiscount || 0)) / 100)).toFixed(2);
                    }
                    return discount;
                });*/
            }

            selectedStartDates.forEach((slotStartDate, i) => {

                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;

                const gameNumber = i + 1;
                let actPrice = 0;

                const actPriceOptions = calculatePriceOption(actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys);
                const personsWithoutOption = persons - actPriceOptions.sumPersons;

                const directDiscountPrice = discountsToSet.find(discount => discount.gameNumber === gameNumber)?.pricePerPerson || null;
                if (directDiscountPrice !== null) {
                    actPrice = directDiscountPrice;
                } else {
                    const recurringDiscountPrice = discountsToSet.find(discount => gameNumber % discount.gameNumber === 0)?.pricePerPerson || null;
                    if (recurringDiscountPrice !== null) {
                        actPrice = recurringDiscountPrice;
                    } else {
                        actPrice = (_slots.find(slot => slotStartDate === slot.startDate)?.priceList?.priceSlot || []).find(price => price.gameNumber === 1)?.pricePerPerson || 0;
                    }
                }

                const actSinglePrice = parseFloat(actPrice || 0);

                newPrices.priceList.push({
                    position: <><b>{productInfo.name}</b> - {moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr</>,
                    persons: personsWithoutOption > 0 ? personsWithoutOption : " ",
                    singlePrice: personsWithoutOption > 0 ? `${actSinglePrice.toFixed(2).replace('.', ',')} €` : " ",
                    summary: personsWithoutOption > 0 ? `${parseFloat(actSinglePrice * personsWithoutOption).toFixed(2).replace('.', ',')} €` : " ",
                });

                if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice)) {

                    priceSurcharge += (parseFloat(String(priceInfo.minPrice)) - (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice));

                }

                // add price options to price list
                actPriceOptions.options.forEach(actPriceOption => {
                    newPrices.priceList.push(actPriceOption);
                });


                // add price options price to summary
                newPrices.summary += actPriceOptions.sumPrice;

                // add single price
                newPrices.singlePrice += actSinglePrice;
                // Add normal price to summary
                newPrices.summary += parseFloat(actSinglePrice * personsWithoutOption);
            });

            let groupDiscountPersonsToUse = (priceInfo?.groupDiscount || []).map((groupDiscount) => groupDiscount.persons).filter((discountPersons) => discountPersons <= allPersons);

            groupDiscountPersonsToUse = Math.max(...groupDiscountPersonsToUse, 0);

            const groupDiscountToUse = (priceInfo?.groupDiscount || []).find((groupDiscount) => groupDiscount.persons === groupDiscountPersonsToUse);



            if (groupDiscountToUse && groupDiscountToUse?.discount > 0 && ( !priceInfo.minPrice || (parseFloat(String(priceInfo.minPrice)) < newPrices.summary) )) {

                newPrices.priceList.push({
                    position: <b>Gruppenrabatt {groupDiscountToUse?.discount} %</b>,
                    persons: "",
                    singlePrice: ` `,
                    discount: true,
                    summary: `- ${((newPrices.summary * (((groupDiscountToUse?.discount || 0)) / 100))).toFixed(2).replace('.', ',')} €`,
                });

                // calculate new single price
                newPrices.singlePrice = (newPrices.singlePrice * ((100 - (groupDiscountToUse?.discount || 0)) / 100)).toFixed(2);

                // subtract group discount from summary
                newPrices.summary = (newPrices.summary * ((100 - (groupDiscountToUse?.discount || 0)) / 100));
            }

            setDiscountPrice(discountsToSet);

        } else if (priceInfo.type === 'tier') {

            selectedStartDates.forEach((slotStartDate) => {
                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;


                const actPriceOptions = calculatePriceOption(actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys);

                const personsWithoutOption = persons - actPriceOptions.sumPersons;

                let priceTierPersonsToUse = (actPriceInfo?.priceTier || []).map((priceTier) => priceTier.persons).filter((pricePersons) => pricePersons <= persons);
                priceTierPersonsToUse = Math.max(...priceTierPersonsToUse, 0);

                const priceTierToUse = (actPriceInfo?.priceTier || []).find((priceTier) => priceTier.persons === priceTierPersonsToUse) || {};

                const actSinglePrice = parseFloat(priceTierToUse?.pricePerPerson || "0");

                newPrices.priceList.push({
                    position: <><b>{productInfo.name}</b> - {moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr</>,
                    persons: personsWithoutOption > 0 ? personsWithoutOption : " ",
                    singlePrice: personsWithoutOption > 0 ? `${actSinglePrice.toFixed(2).replace('.', ',')} €` : ` `,
                    summary: personsWithoutOption > 0 ? `${parseFloat(actSinglePrice * personsWithoutOption).toFixed(2).replace('.', ',')} €` : ` `,
                });

                if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice)) {

                    priceSurcharge += (parseFloat(String(priceInfo.minPrice)) - (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice));

                }

                // add price options to price list
                actPriceOptions.options.forEach(actPriceOption => {
                    newPrices.priceList.push(actPriceOption);
                });

                // add price options price to summary
                newPrices.summary += actPriceOptions.sumPrice;

                // add single price
                newPrices.singlePrice += actSinglePrice;
                // Add normal price to summary
                newPrices.summary += parseFloat(actSinglePrice * personsWithoutOption);
            });

            // round single price
            newPrices.singlePrice = parseFloat(newPrices.singlePrice).toFixed(2);

        } else if (priceInfo.type === 'group') {

            // HINT: currently we have no price options for group prices!!

            selectedStartDates.forEach((slotStartDate) => {
                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;

                let priceGroupPersonsToUse = (actPriceInfo?.priceGroup || []).map((priceGroup) => priceGroup.persons).filter((pricePersons) => pricePersons <= persons);
                priceGroupPersonsToUse = Math.max(...priceGroupPersonsToUse, 0);

                const priceGroupToUse = (actPriceInfo?.priceGroup || []).find((priceGroup) => priceGroup.persons === priceGroupPersonsToUse) || {};

                const actGroupPrice = parseFloat(priceGroupToUse?.pricePerGroup || "0");

                newPrices.priceList.push({
                    position: `${productInfo.name} - ${moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr`,
                    persons: persons,
                    singlePrice: `${actGroupPrice.toFixed(2).replace('.', ',')} €`,
                    summary: `${actGroupPrice.toFixed(2).replace('.', ',')} €`,
                });

                newPrices.groupPrice += actGroupPrice;
                //newPrices.singlePrice += actGroupPrice;


                // round single price
                newPrices.singlePrice = parseFloat(newPrices.singlePrice).toFixed(2);
            });
        }



        newPrices.singlePrice = parseFloat(newPrices.singlePrice).toFixed(2);



        if (newPrices.groupPrice) {

            newPrices.summary = newPrices.groupPrice;

        } else {
            //newPrices.summary =  parseFloat(newPrices.singlePrice * persons).toFixed(2);
            //newPrices.summary += parseFloat(newPrices.singlePrice * persons);

            if (priceSurcharge) {
                newPrices.priceList.push({
                    position: <b>Mindestpreis Aufschlag</b>,
                    persons: "",
                    singlePrice: ` `,
                    discount: false,
                    summary: `${parseFloat(priceSurcharge).toFixed(2).replace('.', ',')} €`,
                });

                newPrices.summary += parseFloat(priceSurcharge);
            }
        }

        /*if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > newPrices.summary) {

            newPrices.priceList.push({
                position: `Mindestpreis Aufschlag`,
                persons: "",
                singlePrice: ` `,
                discount: false,
                summary: `${(parseFloat(String(priceInfo.minPrice)) - newPrices.summary).toFixed(2).replace('.', ',')} €`,
            });

            newPrices.summary = parseFloat(String(priceInfo.minPrice));

        }*/

        newPrices.summary = newPrices.summary.toFixed(2);

        setPrice(newPrices);

    };

    const minAgeChanged = (age) => {
        const actMaxAge = parseInt(form.getFieldsValue(['maxAge'])?.maxAge || "18");
        if (actMaxAge < parseInt(age || "0")) {
            form.setFieldsValue({maxAge: undefined});
        }
        //loadProducts();
        filterProducts();
    };

    const getPriceOptionsPersonsSum = () => {
        const optionValues = form.getFieldsValue("priceOptionsValues").priceOptionsValues || {};
        return priceOptions.reduce((sumPersons, personName) => {
            sumPersons += optionValues?.[personName] || 0;
            return sumPersons;
        }, 0);
    };


    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout={"vertical"}
            size={"large"}
            initialValues={initialValues}
        >
            {error && <Alert type={"error"} message={error} banner />}
            <Row gutter={15}>

                <Col span={24}>
                    <h2>Allgemeine Informationen</h2>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={`Wieviel Personen seid ihr? ${window.ENV.PERSONS_EXTRA_TEXT ? `(${window.ENV.PERSONS_EXTRA_TEXT})` : ``} `}
                        name={"persons"}
                        required={true}
                        rules={[{required: true, message: "Diese Information wird benötigt"}]}
                    >
                        <InputNumber placeholder={"Anzahl der Personen"} inputMode={"numeric"} style={{width: '100%'}} min={1} onChange={() => filterProducts()} />
                    </Form.Item>
                </Col>

                {/*<Col span={24}>
                    <Form.Item
                        label={"Sind alle Personen über 18 Jahre?"}
                        name={"allPlayers18"}
                        required={true}
                        rules={[{required: true, message: "Diese Information wird benötigt"}]}
                    >
                        <Select
                            placeholder={"Sind alle Personen über 18 Jahre?"}
                            style={{width: '100%'}}
                            onChange={(val) => setAges(val)}
                        >
                            <Select.Option value="1">Ja, es sind alle über 18 Jahre alt</Select.Option>
                            <Select.Option value="0">Nein, es sind minderjährige Personen dabei</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>*/}

                {/*<Form.Item shouldUpdate noStyle>
                    {() => form.getFieldsValue(['allPlayers18'])?.allPlayers18 === "0" ?
                        <>*/}
                {window.ENV.ASK_AGE ?
                    <>
                        <Col span={24}>
                            <Form.Item
                                label={<span>Wie alt ist die <u>jüngste</u> Person?</span>}
                                name={"minAge"}
                                required={true}
                                rules={[{required: true, message: "Diese Information wird benötigt"}]}
                            >
                                {/*<Select
                                    placeholder={"Alter auswählen (jüngster)"}
                                    style={{width: '100%'}}
                                    onChange={(age) => minAgeChanged(age)}
                                >
                                    <Select.Option value="7">&lt; 8</Select.Option>
                                    <Select.Option value="8">8-10</Select.Option>
                                    <Select.Option value="10">10-12</Select.Option>
                                    <Select.Option value="12">12-14</Select.Option>
                                    <Select.Option value="14">14-18</Select.Option>
                                    <Select.Option value="18">18+</Select.Option>
                                </Select>*/}
                                <InputNumber placeholder={"Alter jüngster Teilnehmer"}
                                             style={{width: '100%'}}
                                             onChange={(age) => minAgeChanged(age)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={<span>Wie alt ist die <u>älteste</u> Person?</span>}
                                name={"maxAge"}
                                dependencies={['minAge']}
                                required={true}
                                rules={[{required: true, message: "Diese Information wird benötigt"},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('minAge') <= value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Der älteste Teilnehmer kann nicht jünger als der jüngste Teilnehmer sein'));
                                        },
                                    }),
                                ]}
                            >
                                {/*<Radio.Group style={{width: '100%'}}
                                     onChange={() => loadProducts()}
                        >
                            <Space align={"center"} wrap>
                                <Radio.Button value="7">&lt; 8</Radio.Button>
                                <Radio.Button value="8">8-10</Radio.Button>
                                <Radio.Button value="10">10-12</Radio.Button>
                                <Radio.Button value="12">12-14</Radio.Button>
                                <Radio.Button value="14">14-18</Radio.Button>
                                <Radio.Button value="18">18+</Radio.Button>
                            </Space>
                        </Radio.Group>*/}
                                {/*<Select
                                    placeholder={"Alter auswählen (ältester)"}
                                    style={{width: '100%'}}
                                    onChange={() => filterProducts()}
                                >
                                    <Select.Option value="7" disabled={parseInt(form.getFieldsValue(['minAge'])?.minAge || "18") > 7}>&lt; 8</Select.Option>
                                    <Select.Option value="8" disabled={parseInt(form.getFieldsValue(['minAge'])?.minAge || "18") > 8}>8-10</Select.Option>
                                    <Select.Option value="10" disabled={parseInt(form.getFieldsValue(['minAge'])?.minAge || "18") > 10}>10-12</Select.Option>
                                    <Select.Option value="12" disabled={parseInt(form.getFieldsValue(['minAge'])?.minAge || "18") > 12}>12-14</Select.Option>
                                    <Select.Option value="14" disabled={parseInt(form.getFieldsValue(['minAge'])?.minAge || "18") > 14}>14-18</Select.Option>
                                    <Select.Option value="18">18+</Select.Option>
                                </Select>*/}
                                <InputNumber placeholder={"Alter ältester Teilnehmer"}
                                             style={{width: '100%'}}
                                             onChange={() => filterProducts()}
                                />
                            </Form.Item>
                        </Col>
                    </>
                    : null
                }
                {categories.length > 1 ?
                    <>
                        <Col span={24}>
                            <h2>Gewünschtes Produkt wählen</h2>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"category"}
                            >
                                <Select
                                    placeholder={"Kategorie auswählen"}
                                    style={{width: '100%'}}
                                    onChange={() => filterProducts()}
                                >
                                    {categories.map(cat => <Select.Option value={cat} key={cat}>{cat || "N/A"}</Select.Option>)}
                                    <Select.Option value={undefined}>Alle</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </> : null
                }
                {/* </>
                        :
                        <>
                            <Form.Item
                                noStyle
                                name={"minAge"}
                            >
                                <input type={"hidden"} />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                name={"maxAge"}
                            >
                                <input type={"hidden"} />
                            </Form.Item>
                        </>}
                </Form.Item>*/}


                {/*<Col span={24}>
                    <h2>Exklusivbuchung</h2>
                </Col>
                <Col span={24}>
                    <Form.Item

                        name={"exclusiveBooking"}
                        valuePropName={"checked"}
                    >
                        <Checkbox  onChange={() => loadProducts()}>Wir möchten nicht mit anderen Personen zusammenspielen. Mit dieser Option buchst du die gesamte Arena für euch alleine.</Checkbox>
                    </Form.Item>
                </Col>*/}



                    {/*products.length > 1 ?
                        <>*/}

                {/*<Radio.Group onChange={(e) => {setProductInfos(e.target.value);}}>
                                        <Space direction="vertical">
                                            {products.map(product => <Radio
                                                value={product.id}
                                                key={product.id}
                                                disabled={!availableProducts.includes(product.id)}
                                            >{product.name} {product.productDetails && <span style={{margin: '0px 5px'}}><Tooltip title={strip(product.productDetails)}><QuestionCircleOutlined /></Tooltip></span>} <Tag  color={"cyan"}>{product.minPersons} - {product.maxPersons} P</Tag> <Tag color={"blue"}>{product.minAge} - {product.maxAge || "99"} J</Tag> {product.difficulty && <Tag color={"green"}>{productDifficulty?.[product.difficulty]}</Tag>} {product.category && <Tag color={"orange"}>{product.category}</Tag>}</Radio>)}
                                        </Space>
                                    </Radio.Group>*/}


                            <Col span={24}>
                                <Form.Item
                                    label={""}
                                    name={"productId"}
                                    required={true}
                                    rules={[{required: true, message: "Diese Information wird benötigt"}]}
                                >
                                    <ProductSelector
                                        onChange={(productId) => {setProductInfos(productId);}}
                                        products={products}
                                        availableProducts={availableProducts}
                                        hiddenProducts={hiddenProducts}
                                    />
                                </Form.Item>
                            </Col>
                {/*</>
                        :
                        <Col span={24}>
                            <Form.Item
                                name={"productId"}
                                required={true}
                                rules={[{required: true, message: "Diese Information wird benötigt"}]}
                                noStyle
                            >
                                <Input type={"hidden"} />
                            </Form.Item>
                        </Col>
                    */}


                {resources.length > 1 ?
                    <>
                        <Col span={24}>
                            <h2>Einen Raum auswählen</h2>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={"Raum"}
                                name={"resourceId"}
                                required={true}
                                rules={[{required: true, message: "Diese Information wird benötigt"}]}
                            >
                                <Radio.Group onChange={() => {loadSlots(); loadPriceOptions(); }}>
                                    <Space direction="vertical">
                                        {resources.map(resource => <Radio key={resource.id} value={resource.id}>{resource.name}</Radio>)}
                                    </Space>
                                </Radio.Group>

                            </Form.Item>
                        </Col>
                    </>
                : <Col span={24}>
                        <Form.Item
                            name={"resourceId"}
                            required={true}
                            rules={[{required: true, message: "Diese Information wird benötigt"}]}
                            noStyle
                        >
                            <Input type={"hidden"} />
                        </Form.Item>
                    </Col>}

                    {priceOptions.length > 0 ?
                        <Col span={24}>
                            <h2>{`${window.ENV.PRICEOPTIONS_TITLE_TEXT ? `${window.ENV.PRICEOPTIONS_TITLE_TEXT}` : `Zusätzliche Informationen`}`}</h2>
                        </Col>
                    : null}

                    <Col span={24}>
                        <Row gutter={15}>
                        {priceOptions.map(option => (<Col span={12}>
                                <Form.Item
                                    dependencies={["persons"]}
                                    label={option}
                                    name={["priceOptionsValues", option]}
                                    rules={[ { validator: async () => {
                                            if(getPriceOptionsPersonsSum() > (form.getFieldsValue("persons").persons || 1)) {
                                                throw new Error("Die Summe der Personenangaben überschreitet die oben angegebene Personenanzahl.")
                                            }
                                        }}]}
                                >
                                    <InputNumber placeholder={"Anzahl"}
                                                 style={{width: '100%'}}
                                                 min={0}
                                                 onChange={() => loadSlots(false)}
                                    />
                                </Form.Item>
                            </Col>))}
                        </Row>
                    </Col>

                <Col span={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate
                    >
                        {() =>
                        getPriceOptionsPersonsSum() > (form.getFieldsValue("persons").persons || 1) ?
                            <>
                                <Alert
                                    showIcon
                                    type="warning"
                                    style={{width: '100%'}}
                                    message='Die Summe der Personenangaben überschreitet die oben angegebene Personenanzahl. Die Gesamtpersonenanzahl oder die Angaben in den "Zusätzlichen Informationen" müssen korrigiert werden.'
                                    //action={<Button type="primary" size="small" htmlType="button" onClick={() => { form.setFieldsValue({persons: getPriceOptionsPersonsSum()}); filterProducts(false); }}>Anzahl übernehmen</Button>}
                                />
                                <br/>
                            </>
                         : null }
                    </Form.Item>
                </Col>

                {availableProducts.length === 0 && productError === productErrors.NONE_AVAILABLE ?
                    <>
                        <Col span={24}>
                            <BookingMessageBlock icon={<PhoneOutlined />} text={<><span>Bitte für die Reservierung telefonisch an uns wenden.</span><br/>{mandatorInfo?.phone && <a href={`tel:${mandatorInfo?.phone}`}><b>Telefon: {mandatorInfo?.phone}</b></a>}</>} />
                        </Col>
                        <Col span={24}>
                            <br/>
                        </Col>
                    </>
                    :
                    <>
                        <Col span={24}>
                            <h2>Datum und Uhrzeit</h2>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={""}
                                name={"date"}
                                required={true}
                                rules={[{required: true, message: "Diese Information wird benötigt"}]}
                                trigger={"onDayClick"}
                                valuePropName={'selected'}
                            >
                                <DayPicker
                                    //disabled={[{ before: minBookingDate }]}
                                    //disabled={customBookingDates.length > 0 ? [{before: Date.now()}] : [{ before: minBookingDate }]}
                                    disabled={[{ before: minBookingDate }, ...customBookingDates]}
                                    mode={"single"}
                                    locale={de}
                                    onDayClick={() => loadSlots()}
                                    showOutsideDays
                                    defaultMonth={isValidDate(initialValues.date) ? initialValues.date : new Date()}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prev, next) => prev.date !== next.date}
                            >
                                {() => <Form.Item
                                    label={"Uhrzeiten auswählen"}
                                    name={"bookingSlots"}
                                >
                                    <BookingSlotSelector slots={slots} multiSelect={multiSlotsBooking} onChange={(slotStartDates) => setPrices(slotStartDates)} persons={form.getFieldsValue(['persons'])?.persons || 0} />
                                </Form.Item>}
                            </Form.Item>
                            {slots.length === 0 && slotError === slotErrors.SLOTS_FULL && <>
                                <BookingMessageBlock icon={<InfoCircleOutlined />} text={<><span>Für die gewünschten Daten ist an diesem Tag leider nichts mehr verfügbar.</span></>} />
                                <br/>
                            </>
                            }

                        </Col>
                    </>
                }


                <Col span={24}>
                    <Space direction={"vertical"} style={{width: '100%'}}>
                        {discountPrice.map(discount => <Alert showIcon type={"info"} message={<span>Jede {discount.gameNumber}. Buchung {discount.pricePerPerson === "0.00" ? <b>kostenlos</b> : <span>für nur <b>{discount.pricePerPerson.replace(".", ",")} &euro;</b> pro Person</span>} hinzufügen</span>} />) }
                    </Space>
                    <br/><br/>
                </Col>

                <Col span={24}>
                    <br/>
                </Col>

                <Col span={24}>

                    <Collapse
                        expandIcon={({ isActive }) => <DownOutlined style={{color: 'var(--global-main-color)', fontSize: '1.3rem', marginTop: '7px'}} rotate={isActive ? 180 : 0} />}
                    >
                        <Panel showArrow={true} header={<h1 style={{margin: '0'}}>Artikelpreis Brutto: {(price?.summary || "").toString().replace(".", ",")} &euro;</h1>} key="1">
                            <PriceTable price={price} />
                        </Panel>
                    </Collapse>
                    <br/>

                    {/*<h1>Preis pro Person: {price.singlePrice.toString().replace(".", ",")} &euro;</h1>
                    <h1>Gesamtpreis: {price.summary.toString().replace(".", ",")} &euro;</h1>*/}
                </Col>

                {showBack ?
                    <Col span={12}>
                        <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={onBack}>Zurück</Button>
                    </Col>
                : null}

                <Col span={showBack ? 12 : 24}>
                    <Form.Item
                        noStyle
                        shouldUpdate
                    >
                        {() =>
                            <Button size={"large"} block disabled={saving || (form.getFieldsValue(['bookingSlots'])?.bookingSlots ||[]).length === 0} type={"primary"} htmlType={"submit"}
                                    loading={saving}>Nächster Schritt</Button>
                        }
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    );

}

export default Book;
