import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Row,
    Form,
    Radio,
    Space,
    Input, Alert, Checkbox
} from "antd";
import api from "../../middleware/api";
import PriceTable from "./PriceTable";
import {useApp} from "../app/AppProvider";
import {getCookie} from "../../helpers/cookie";
import {initGTAG} from "../../helpers/gtag";

const { TextArea } = Input;

const BookPayment = ({onSuccess, onBack, initialValues}) => {

    const [saving, setSaving] = useState(false);
    const [profileData, setProfileData] = useState(false);
    const [error, setError] = useState(false);

    const {mandatorInfo} = useApp();


    const [form] = Form.useForm();

    const fetchUserData = async () => {
        const response = await api.get(`customer/profile`);
        if(response?.data)
        {
            setProfileData(response.data);
        }
    };

    useEffect(() => {
        if(!getCookie('rcl_marketing_consent')) {
            initGTAG(true);
        }
    }, []);

    useEffect(() => {
        if(initialValues?.customerId) {
            fetchUserData();
        }
    }, [initialValues?.customerId]);


    const handleSubmit = async (values) => {
        try {
            setSaving(true);

            const saveData = { ...initialValues, ...values };

            const queryParams = initialValues?.extraSession ? `?extraSession=${initialValues?.extraSession}` : '';

            const response = await api.put(`booking/public${queryParams}`, saveData);

            if (response.data) {
                onSuccess(values);
            }


        } catch(e) {
            window.scrollTo(0,0);
            switch(e.response.status) {
                case 410:
                    setError("Die Reservierungszeiten sind leider nicht mehr verfügbar. Bitte eine andere Zeit auswählen.");
                    break;
                default:
                    setError("Es ist ein unbekannter Fehler aufgetreten. Bitte später erneut versuchen.");
                    break;
            }
        } finally {
            setSaving(false);
        }
    };


    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout={"vertical"}
            size={"large"}
            initialValues={{...initialValues, payment: 'cash' }}
        >
            {error && <Alert type={"error"} message={error} banner />}
            <Row gutter={15}>

                <Col span={24}>
                    <h1 className={"login-H2"}>
                        Zusammenfassung
                    </h1>
                    <p>Bitte die Informationen in der Zusammenfassung nochmals überprüfen und die Reservierung abschließen.</p>
                    <br/>
                </Col>


                <Col xs={24} md={12}>
                    <h2>Anschrift <a href={"#"} style={{fontSize: '0.8em', marginLeft: '15px'}} onClick={() => onBack(1)}>ändern</a></h2>
                    <span>{initialValues?.guest?.firstName || profileData.firstName} {initialValues?.guest?.lastName || profileData.lastName}</span><br/>
                    <span>{initialValues?.guest?.address || profileData.address}</span><br/>
                    <span>{initialValues?.guest?.zipCode || profileData.zipCode} {initialValues?.guest?.city || profileData.city}</span><br/>
                    <span>{initialValues?.guest?.mail || profileData.mail}</span><br/>
                    <span>{initialValues?.guest?.phone || profileData.phone}</span><br/>
                    <br/>
                </Col>

                <Col xs={24} md={12}>
                    <h2>Zahlungsmethode</h2>

                    {/*<Row>
                        <Col span={6}>
                            <span>Personen:</span>
                        </Col>
                        <Col span={18}>
                            <span><b>{initialValues?.persons}</b></span>
                        </Col>

                        <Col span={6}>
                            <span>Datum:</span>
                        </Col>
                        <Col span={18}>
                            <span><b>{moment(initialValues?.date).format("DD.MM.YYYY")}</b></span>
                        </Col>

                        <Col span={6}>
                            <span>Uhrzeiten:</span>
                        </Col>
                        <Col span={18}>
                            <span><b>{(initialValues?.bookingSlots || []).map(slot => <><span>{moment(slot).format('HH:mm')} Uhr</span><br/></>)}</b></span>
                        </Col>
                    </Row>*/}

                        <Form.Item
                            name={"payment"}
                            required={true}
                            rules={[{required: true}]}
                        >
                            <Radio.Group style={{width: '100%'}}>
                                <Space align={"center"} wrap>
                                    <Radio value="cash">vor Ort (Gutschein, Bar- oder Kartenzahlung)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                    <br/>

                    {/*

                    <span>Personen: <b>{initialValues?.persons}</b></span><br/>
                    <span>Datum: <b>{moment(initialValues?.date).format("DD.MM.YYYY")}</b></span><br/>
                    <span>Uhrzeiten: <b>{(initialValues?.bookingSlots || []).map(slot => moment(slot).format('HH:mm') + " Uhr")}</b></span><br/>

                    */}
                </Col>

                <Col span={24}>
                    <h2>Reservierungsinformationen <a href={"#"} style={{fontSize: '0.8em', marginLeft: '15px'}} onClick={() => onBack(0)}>ändern</a></h2>
                </Col>

                <Col span={24}>
                    <PriceTable price={initialValues.prices} />
                    <br/>
                </Col>


                <Col span={24}>
                    <Form.Item
                        label={"Bemerkung"}
                        name={"message"}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.Item
                        label={""}
                        name={"dsr"}
                        valuePropName={"checked"}
                        required={true}
                        initialValue={false}
                        rules={[{required: true, transform: value => (value || undefined), type: 'boolean', message: "Bitte stimme unseren Bestimmungen zu."}]}
                    >
                        <Checkbox>Ich willige ein, dass meine Daten zum Zwecke der Reservierung unter Berücksichtigung des Datenschutzgesetzes gespeichert werden. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Dies kann ich jederzeit schriftlich widerrufen. Ausführliche Informationen stehen in unserer <a target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} href={`${mandatorInfo?.privacyUrl}`}>Datenschutzerklärung</a>.</Checkbox>
                    </Form.Item>
                </Col>


                <Col span={12}>
                    <Button size={"large"} block disabled={saving} type={"default"} htmlType={"button"} onClick={() => onBack()}>Zurück</Button>
                </Col>

                <Col span={12}>
                    <Form.Item
                        noStyle
                        shouldUpdate
                    >
                        {() =>
                            <Button size={"large"} block disabled={saving || !form.getFieldsValue(['dsr'])?.dsr} type={"primary"} htmlType={"submit"}
                                    loading={saving}>Jetzt reservieren</Button>
                        }
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <br/>
                    <p style={{textAlign: 'center'}}>Stornierung bis 24 Stunden vor dem reservieren Termin kostenfrei möglich.</p>
                </Col>
            </Row>
        </Form>
    );

}

export default BookPayment;
