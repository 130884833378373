export function gtag_track_payment({callback, price}) {

    let trackingAttempted = false;

    function fallbackCallback() {
        if (!trackingAttempted) {
            console.log("Fallback: Tracking blocked or failed, executing callback.");
            callback();
        }
    }

    // Setze einen Timeout als Fallback, falls das Tracking blockiert wird.
    const fallbackTimeout = setTimeout(fallbackCallback, 300); // 2000 Millisekunden = 2 Sekunden

    gtag_report_conversion(() => {
        clearTimeout(fallbackTimeout); // Wenn das Tracking erfolgreich ist, deaktiviere den Fallback
        trackingAttempted = true;
        callback && callback();
    }, () => window.gtag('event', 'conversion', {
        'send_to': window.ENV.GTAG_CONVERSION,
        'value': price,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': () => {
            clearTimeout(fallbackTimeout); // Sicherstellen, dass der Timeout nach erfolgreichem Event abgebrochen wird
            trackingAttempted = true;
            callback && callback();
        }
    }));
}

function gtag_report_conversion(cb, gtagFunction) {
    const callback = cb || function () {};
    const gtagFunc = gtagFunction || function () {};

    if(window.gtag) {
        gtagFunc();
    } else {
        callback();
    }
}

export function initGTAG(noCookie = false) {
    if(window.ENV.GTAG_ID) {
        let ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
        ga.src = `https://www.googletagmanager.com/gtag/js?id=${window.ENV.GTAG_ID}`;
        let s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        if(noCookie) {
            gtag('config', window.ENV.GTAG_ID, {'conversion_linker': false});
        } else {
            gtag('config', window.ENV.GTAG_ID);
        }
        window.gtag = gtag;
    }
};
